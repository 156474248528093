<template>
  <mi-drawer
    class="flex justify-between"
    :model-value="true"
    :mini="!opened"
    width="288"
    mini-width="70"
    side="right"
    bordered
    overlay
    persistent
  >
    <q-scroll-area class="fit">
      <mi-list-item class="items-center q-pa-md">
        <mi-list-item-section class="text-h6 text-weight-bold q-pl-xs text-family-condensed">
          Details
        </mi-list-item-section>
        <mi-btn
          :ripple="false"
          icon="arrows-right"
          icon-type
          icon-size="18px"
          class="collapse-icon q-mx-auto"
          :class="{ 'rotate-180': !opened }"
          dense
          fab
          flat
          @click="toggle"
        ></mi-btn>
      </mi-list-item>
      <mi-list-item class="items-center q-pa-md">
        <mi-list-item-section class="text-subtitle2 not-usable-choices-drawer__subtitle">
          <div class="flex column">
            <span class="text-body3 text-weight-bold">Main date</span>
            <span class="text-weight-regular">{{ resultDate }}</span>
          </div>
          <div class="flex column">
            <span class="text-body3 text-weight-bold">Compared to</span>
            <span class="text-weight-regular">{{ compareDateWith }}</span>
          </div>
        </mi-list-item-section>
      </mi-list-item>
      <mi-list-item class="items-center q-py-md not-usable-choices-drawer__columns-subtitle">
        <mi-list-item-section class="text-subtitle2 text-primary text-weight-bold q-pl-xs">
          Columns display
        </mi-list-item-section>
      </mi-list-item>
      <mi-list-item class="flex column items-right">
        <mi-list-item-section class="text-subtitle2 text-primary text-weight-bold q-px-xs">
          <mi-checkbox
            v-for="(option) in options.filter(option => option.isDynamic)"
            :key="option.label"
            class="not-usable-choices-drawer__checkbox"
            :model-value="option.checked"
            :disable="false"
            :label="option.label"
            @update:model-value="handleCheckboxClick(option)"
          >
          </mi-checkbox>
        </mi-list-item-section>
      </mi-list-item>
    </q-scroll-area>
  </mi-drawer>
</template>

<script>
  export default {
    name: 'NotUsableChoicesDrawer',
    props: {
      opened: {
        type: Boolean,
        required: true
      },
      toggle: {
        type: Function,
        required: true
      },
      options: {
        type: Array,
        required: false,
        default: () => []
      },
      handleCheckboxClick: {
        type: Function,
        required: false,
        default: () => {}
      },
      resultDate: {
        type: String,
        required: false,
        default: ''
      },
      compareDateWith: {
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .not-usable-choices-drawer {
    &__checkbox {
      padding-bottom: 1rem;
      font-weight: 400;

      ::v-deep(.q-checkbox__label) {
        padding-left: .75rem;
      }
    }

    &__columns-subtitle {
      padding-bottom: 8px;
    }

    &__subtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #eaedf366;
      padding: .75rem;
      border-radius: 4px;
    }

    &__bottom-button {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 16px;
    }
  }
</style>
