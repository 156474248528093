export const columns = [
  {
    name: 'index',
    label: '#',
    field: 'index',
    style: 'min-width: 60px; max-width: 60px;',
    align: 'left'
  },
  {
    name: 'optionName',
    label: 'Option',
    align: 'left',
    field: row => row.optionName,
    format: val => `${ val }`,
    sortable: true,
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'min-width: 300px; max-width: 300px;'
  },
  {
    name: 'choiceName',
    align: 'left',
    label: 'Not usable choice',
    field: row => row.choiceName,
    sortable: true,
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'min-width: 300px; max-width: 300px;'
  },
  {
    name: 'numberOfStateChangesForChoiceAcrossComparedValidations',
    align: 'center',
    label: 'Updates',
    field: row => row.numberOfStateChangesForChoiceAcrossComparedValidations,
    sortable: true,
    showSortElement: true,
    sortDirection: 'ASC',
    style: 'min-width: 50px;'
  }
]

export const notUsableChoicesComparisonTypes = {
  IS_NOW_NOT_USABLE: {
    value: 'IS_NOW_NOT_USABLE',
    class: 'not-usable-choices__minus-circle-color not-usable-choices-table__minus-circle-color mi-icon-minus-circle',
    label: 'Not usable choice'
  },
  IS_NOW_USABLE: {
    value: 'IS_NOW_USABLE',
    class: 'not-usable-choices__check-circle-color not-usable-choices-table__check-circle-color mi-icon-check-circle',
    label: 'Usable choice'
  },
  MISSING_DATA: {
    value: 'MISSING_DATA',
    class: 'mi-icon-warning-circle-outline',
    label: 'Missing data'
  }
}

export const defaultPaginationParams = {
  limit: 500,
  offset: 0
}

export const buildUrlParams = ({ sortBy, sortDirection, limit = 500, offset = 0 }) => {
  let sortByAlias = 'UPDATES'

  if (sortBy === 'optionName') sortByAlias = 'OPTION'
  if (sortBy === 'choiceName') sortByAlias = 'CHOICE'

  const params = new URLSearchParams()
  params.append('sortBy', sortByAlias)
  params.append('sortDirection', sortDirection || 'DESC')
  params.append('limit', limit)
  params.append('offset', offset)

  return params
}
