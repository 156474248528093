<template>
  <div class="q-pa-md">
    <mi-table
      ref="miTable"
      :class="['not-usable-choices-table', fixedColumns]"
      :rows="rows"
      :columns="tableColumns"
      :loading="loading"
      loading-label="Loading results. Please wait ..."
      no-data-label="No data available"
      row-key="index"
      hide-pagination
      wrap-cells
      sticky-header
      :on-change-sort-table-column-visibility="onChangeSortTableColumnVisibility"
      :on-change-sort-table-direction="onChangeSortTableDirection"
      :custom-top-row="false"
    >
      <template #header="props">
        <q-tr :props="props" class="bg-white mi-table__header mi-table__header--sticky">
          <q-th
            v-for="col in tableColumns"
            :key="col.name"
            class="text-left sortable sorted"
            :style="col.style"
          >
            <div
              v-if="col.sortable"
              @mouseenter="onChangeSortTableColumnVisibility(col.name, true)"
              @mouseleave="onChangeSortTableColumnVisibility(col.name, false)"
              @click="onChangeSortTableDirection(col.name)"
            >
              {{ col.label }}
              <i
                v-if="col.showSortElement"
                :class="createSortDirectionClass(col.sortDirection)"
                class="q-table__sort-icon q-table__sort-icon--left"
                aria-hidden="true"
                role="presentation"
              > </i>
            </div>
            <div v-else>
              {{ col.label }}
            </div>
          </q-th>
        </q-tr>
      </template>
      <template #top-row="props">
        <q-tr :props="props" class="bg-white mi-table__header mi-table__header--sticky">
          <q-td
            v-for="(col, index) in props.cols"
            :key="index"
            :class="parseInt(index) < numberOfFixedColumns ?
              'not-usable-choices-table__hide-border' : 'not-usable-choices-table__display-border'"
          >
            <div v-if="col.isDynamic && col.numberOfStateChanges > 0" class="text-center text-weight-bold">
              {{ col.numberOfStateChanges }} updates
            </div>
            <div
              v-else-if="col.isDynamic && col.numberOfStateChanges === 0"
              class="text-center text-weight-bold not-usable-choices-table__display-border__updates-disabled"
            >
              {{ col.numberOfStateChanges }} updates
            </div>
          </q-td>
        </q-tr>
      </template>
      <template #body-cell="props">
        <q-td :props="props" :class="getBackgroundClass({ col: props.col, value: props.value })">
          <div v-if="props.col.isDynamic" class="row justify-center">
            <span :class="notUsableChoicesComparisonTypes[props.value]?.class"></span>
          </div>
          <template v-else>
            {{ props.value }}
          </template>
        </q-td>
      </template>
    </mi-table>
  </div>
</template>

<script>
  import { ref, computed, watch } from 'vue'
  import { notUsableChoicesComparisonTypes } from './utils'

  export default {
    name: 'NotUsableChoicesTable',
    props: {
      rows: {
        type: Array,
        required: true,
        default: () => []
      },
      columns: {
        type: Array,
        required: false,
        default: () => []
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      onChangeSortTableColumnVisibility: {
        type: Function,
        required: false,
        default: () => {}
      },
      onChangeSortTableDirection: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    setup(props) {
      const numberOfFixedColumns = ref(4)
      const tableColumns = ref(props.columns)

      const getBackgroundClass = ({ col, value }) => {
        if (col.isDynamic && (
          value === notUsableChoicesComparisonTypes.IS_NOW_USABLE.value
          || value === notUsableChoicesComparisonTypes.IS_NOW_NOT_USABLE.value
        )) {
          return 'bg-white'
        }
        return ''
      }

      const createSortDirectionClass = direction => `q-icon mi-icon-caret-${ direction === 'DESC' ? 'down' : 'up' }`

      const fixedColumns = computed(
        () => `sticky-cols-${ numberOfFixedColumns.value }`
      )

      watch(() => [props.columns], async () => {
        tableColumns.value = props.columns.filter((column => !column.isDynamic || column.checked))
      })

      return {
        fixedColumns,
        getBackgroundClass,
        numberOfFixedColumns,
        notUsableChoicesComparisonTypes,
        createSortDirectionClass,
        tableColumns
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .not-usable-choices-table {
    min-height: 300px;
    max-height: calc(100vh - 250px);
    max-width: calc(100vw - 85px);

    ::v-deep(.q-table) td {
      background-color: $mi-silver-50;
      border-right: 3px solid $mi-silver-200;
    }

    &__minus-circle-color {
      color: $mi-red-500;
    }

    &__check-circle-color {
      color: $mi-turquoise-600;
    }

    &__hide-border {
      border: 0 !important;
      background-color: $mi-silver-50 !important;
    }

    &__display-border {
      border: 1px solid $mi-silver-200;
      color: $mi-silver-800;

      &__updates-disabled {
        color: $mi-silver-500;
      }
    }

    @mixin sticky-column($j, $is-th) {
      position: sticky;
      left: if($j == 1, 0, if($j == 2, 60px, calc(300px * #{$j - 2} + 60px)));
      z-index: if($is-th, 2, 1);

      @if not $is-th {
        background-color: #ffffff;
        border: 1px solid $mi-silver-200;
      }
    }

    @for $i from 1 through 12 {
      &.sticky-cols-#{$i} {
        @for $j from 1 through $i {
          ::v-deep(.q-table) th:nth-child(#{$j}) {
            @include sticky-column($j, true);
          }
          ::v-deep(.q-table) td:nth-child(#{$j}) {
            @include sticky-column($j, false);
          }
        }
      }
    }
  }
</style>
